import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export default class Terms extends React.Component {
  state = {
    termsData: null,
  }

  componentDidMount() {

  }

  render() {
    return (
      <Layout>
        <div className="relative overflow-hidden">
          <div className="max-w-screen-xl mx-auto p-6 md:pt-12 sm:p-8">
            <div className="md:flex p-4 flex-col">
              <Header>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 24,
                    }}
                    className="mt-2 text-xs sm:text-sm md:text-md para"
                  >
                    Being.Zone
                  </p>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                    }}
                    className="mt-2 text-xs sm:text-sm md:text-md para"
                  >
                    Terms and Conditions
                  </p>
                </div>
              </Header>
              <div className="legal-container mt-4" style={{
                fontWeight: 500,
                fontSize: 15,
                color: "#000"
              }}>
                <p className="font-bold">
                  This Terms & Conditions was last updated on March 4, 2022.
                </p>
                <p>These Terms & Conditions (these "Terms") contain the terms and conditions on which we supply content, products or services listed on www.being.zone (the "Website"), through our applications (the "Apps") or via other delivery methods to you (the Website and such content, products, services and the Apps are collectively referred to herein as the "Product" or "Products"). Please read these terms and conditions, carefully before ordering/using any Products from the Website or third party App stores (e.g. the Apple App Store, the Android Play Store, etc.). The terms "being.zone," "BeingZone," "us" or "we" refer to being.zone and it is being wholly owned India company, 8anga Life Private Limited, you ("You" and, where applicable, "Your") indicate Your acceptance of the following terms from BeingZone. You agree to be bound by all the terms and conditions of this Agreement. You agree that it is enforceable as if it were a written negotiated agreement signed by You. If You do not agree to the terms of this Agreement, You may not access, download and/or use the BeingZone Services. If You are entering into this Agreement on behalf of a company or other legal entity, You hereby represent that You have the authority to bind such entity to this Agreement, in which case the term "You" shall be construed to refer to such entity.
                </p>
                <p className="font-bold">
                  GENERAL
                </p>
                <p>BeingZone offers services like life coaching, performance coaching, leadership coaching, personal and professional development, which includes connecting people who seek to change their lives ("Customers") to professionally certified coaches collectively referred to as ("Users")
to obtain online consultation through video conference or otherwise referred as ("Consultations"). BeingZone services and products offer short-term courses for self-development referred as ("Products"). BeingZone will provide services and products as orders that will be submit through BeingZone. Each order as applicable as indivudal purchase, subscription based associated fees for BeingZone services. BeingZone must accept the order to become valid. Customers, Coaches, Organizations, Startups, NGO, Government Bodies, and Educational Institutions will be deemed to respective Agreement by reference and made an integral part of this Agreement. At any point this Agreement's terms govern. 
                </p>

                <p className="font-bold">
                  Relationship of Users:
                </p>
                <p>
                  All Users acknowledge and understand that BeingZone is not an employer, broker, agent or insurer for any Users of the Service. As the Service solely provides a platform and tools for Users to communicate with potential transaction partners, any such transactions are made by all Users at each User's own risk.
                </p>

                 <p className="font-bold">
                  Modifications to Service:
                </p>
                <p>
                  BeingZone reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that BeingZone will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
                </p>

                 <p className="font-bold">
                  Interactions and Restrictions
                </p>
                <p>
                  Customers interactions with BeingZone for the Services and Products are subject to this Agreement. BeingZone may use any data, information or materials collected or received from You through these interactions, in accordance with this Agreement and the Privacy Policy. You are responsible for establishing Your own policies (if any) with Your employer as to what work or business information is appropriate to share as part of the BeingZone Services. You will not (and will not allow anyone else to) (a) rent, lease, copy, disclose, provide access to or sublicense the BeingZone Platform, (b) use the BeingZone Platform for the benefit of, or to provide any service to, a third party, or (c) publicly disseminate information regarding the performance of the BeingZone Platform. 
                </p>

                 <p className="font-bold">
                  Fees and Payment Terms
                </p>
                <p>
                  The fees and payment terms applicable to the BeingZone Services, Products, and related support are set forth in the applicable Order.
                </p>

                 <p className="font-bold">
                  Term, Termination and Effects of Termination
                </p>
                <p>
                  Unless earlier terminated as set forth in this Agreement, this Agreement commences upon the date You first access, download or use the BeingZone Services ("Effective Date") and continues to the end of the last effective Subscription Period. BeingZone may change the pricing for Subscription Terms subsequent to the initial Term (each a "Renewal Term"), in its sole discretion. Either party may terminate this Agreement and any Order hereunder upon immediate effect for any reason whatsoever, notwithstanding BeingZone may terminate this Agreement and any Order hereunder if You violate or breach the Acceptable Use Policy. Upon any expiration or termination of this Agreement, (a) BeingZone will cease providing the BeingZone Services and (b) You will cease all access to the BeingZone Platform, Services, and Products unless permitted by BeingZone. Notwithstanding any terms to the contrary in this Agreement, (i) in addition to the mentioned terms, Sections like Disclaimer, Interactions and Restrictions, Disclaimer, Representations will survive any termination or expiration of this Agreement, and (ii) no refunds will be issued.
                </p>
                <p>
                Trial license: As part of the trial Services, you will be offered 1 free coaching session ("Complimentary Coaching Session"). If you fail to attend Your first schedule Complimentary Coaching Session, BeingZone reserves the right, in its absolute discretion, to limit your ability to reschedule an additional Complimentary Coaching Session. BeingZone may also withdraw or modify any free trial offer and/or the BeingZone trial terms and conditions at any time without prior notice and with no liability.
                </p>

                 <p className="font-bold">
                  Representations
                </p>
                <p>
                  Each party represents and warrants that (a) this Agreement is valid, binding and enforceable against it in accordance with its terms, and (b) it will fulfill its obligations under this Agreement in accordance with all applicable laws.
                </p>

                 <p className="font-bold">
                  Disclaimer
                </p>
                <p className="font-bold">
                  WE ARE NOT A HEALTH CARE OR MEDICAL DEVICE PROVIDER, NOR SHOULD THE SERVICES BE CONSIDERED MEDICAL ADVICE OR THERAPY SERVICES. ONLY YOUR PHYSICIAN OR OTHER HEALTH CARE PROVIDER CAN OFFER MEDICAL ADVICE TO YOU. 
                </p>
                <p>
                  EXCEPT AS SET FORTH IN THIS SECTION, BEINGZONE MAKES NO WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, OR NON INFRINGEMENT. COACHING DOES NOT CONSTITUTE MEDICAL ADVICE OR THERAPY. BEINGZONE WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR OTHER PROBLEMS INHERENT IN THE USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE REASONABLE CONTROL OF BEINGZONE.
                </p>

                 <p className="font-bold">
                  Limitation of Liability
                </p>
                <p>
                  YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASED PARTIES WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE RELEASED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL THE RELEASED PARTIES’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID THE RELEASED PARTIES IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
                </p>
                <p>
                SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE.
                </p>

                 <p className="font-bold">
                  Agreement to Arbitrate: 
                </p>
                <p>
                  This Dispute Resolution by Binding Arbitration section is referred to in these Terms of Service as the “Arbitration Agreement.” You agree that any and all disputes or claims that have arisen or may arise between you and BeingZone, whether arising out of or relating to these Terms of Service (including any alleged breach thereof), the Services, any advertising, any aspect of the relationship or transactions between us, shall be resolved exclusively through final and binding arbitration, rather than a court, in accordance with the terms of this Arbitration Agreement, except that you may assert individual claims in small claims court, if your claims qualify. Further, this Arbitration Agreement does not preclude you from bringing issues to the attention of federal, state, or local agencies, and such agencies can, if the law allows, seek relief against us on your behalf. You agree that, by entering into these Terms of Service, you and BeingZone are each waiving the right to a trial by jury or to participate in a class action. Your rights will be determined by a neutral arbitrator, not a judge or jury. The Federal Arbitration Act governs the interpretation and enforcement of this Arbitration Agreement.
                </p>

                 <p className="font-bold">
                  Confidentiality
                </p>
                <p>
                  "Confidential Information" is non-public information of a party which is provided to the other party hereunder and which is either designated as confidential or of a type which should be recognized by a commercially reasonable party as confidential. Confidential Information of BeingZone includes the Services and its related documentation and materials, along with these terms and conditions. The party receiving Confidential Information may use it only for purposes connected with the facilitation of these Terms or Services and may not disclose it to any third party unless such third party is supporting the provision of the Services or otherwise performing obligations hereunder and is bound in writing by confidentiality restrictions substantially similar to those required hereunder. The obligations to maintain the Confidential Information as confidential shall remain in place for so long as the applicable information is maintained as confidential by the owning party. The limitations on disclosure or use of Confidential information shall not apply to information which (i) is rightfully obtained by the recipient without breach of any confidentiality obligation; (ii) is or becomes known to the public through no act or omission of the recipient; (iii) the recipient develops independently without using Confidential Information of the other party; or (iv) is disclosed in response to a valid court or governmental order, if the recipient has given the other party prior written notice (unless prohibited by law or governmental authority) and provides reasonable assistance.
                </p>

                 <p className="font-bold">
                  Ownership
                </p>
                <p>
                  As between the parties and subject to the grants expressly set forth in this Agreement, BeingZone owns all right, title and interest in and to the BeingZone Services, Products, and anonymous/aggregate data, feedback, and any and all patent rights, copyrights, trademark rights, trade secret rights and other intellectual property rights embodied in or related to the foregoing. 
                </p>

                 <p className="font-bold">
                  Subcontractors
                </p>
                <p>
                  BeingZone may use the services of third-party coaches and other subcontractors and permit them to provide the BeingZone Services. 
                </p>

                <p className="font-bold">
                  Third-Party Tools
                </p>
                <p>
                  You acknowledge and agree that the BeingZone Services utilize certain third-party videoconferencing, communications tools and other services in connection with BeingZone Services, e.g., Zoom, Skype or FaceTime ("Third-Party Tools"). While Your data provided to BeingZone through Third-Party Tools remains subject to these terms, use of Third-Party Tools themselves is subject to the terms and conditions of the Third-Party Tool providers. BeingZone is not responsible for the operation of or any changes to the Third-Party Tools or the acts or omissions of Third-Party Tool providers. 
                </p>

                <p className="font-bold">
                  Assignment
                </p>
                <p>
                  BeingZone may transfer its rights and obligations under this Agreement to any company, firm or person at any time if it does not materially affect Your rights under it. You may not transfer your rights or obligations under these Terms to anyone else. This Agreement is personal to You and no third party is entitled to benefit under this Agreement except as provided here  
                </p>

                <p className="font-bold">
                  Notices
                </p>
                <p>
                  Unless otherwise specifically indicated, all notices given by You to us must be given to contact@being.zone. We may give notice to You at the e-mail address You provide to us when You register or in any other way. Notices will be deemed given as of the day they are sent by email, messenger, delivery service, or mail (postage prepaid, certified or registered, return receipt requested), and addressed as set forth below, or to such other address as the party to receive the notice so designates by notice. 
                </p>

                <p className="font-bold">
                  Waiver and Severability
                </p>
                <p>
                  A party's delay or failure to exercise a right or remedy will not result in a waiver of that right or remedy. If a court of competent jurisdiction holds any provision of this Agreement to be illegal, invalid or unenforceable under the governing law, the remaining provisions will remain in full force and effect, and will be construed so as to most nearly reflect the parties' intent with respect to such provision. 
                </p>

                 <p className="font-bold">
                  Miscellaneous
                </p>
                <p>
                  This Agreement, including all Orders, constitutes the entire Agreement between the parties and supersedes all previous or contemporaneous agreements between the parties, relating to its subject matter, and any change to its terms must be in writing and agreed by each of the parties.
                </p>

                 <p className="font-bold">
                  Basis of License
                </p>
                <p>
                  (a) These Terms and the Order set out the whole Agreement between you and us for the supply of the BeingZone Services and Products. In order to participate in certain Services and Products, you may be required to agree to additional terms and conditions; those additional terms are hereby incorporated into these Terms. Where such terms are inconsistent with these Terms, the additional terms shall control.
                </p>
                <p>
                  (b) Please check that the details in these Terms and on the Order are complete and accurate before you use or commit yourself to purchase the Products. If you think that there is a mistake, please make sure that you ask us to confirm any changes in writing, as we only accept responsibility for statements and representations made in writing by an officer of being.zone.
                </p>
                <p>
                  (c) I understand that I will receive regular tips, notifications, invites and other emails from the platform once I register and that I have the option to unsubscribe in case I do not want to see these
                </p>

                 <p className="font-bold">
                  Changes to Terms
                </p>
                <p>
                  BeingZone reserves the right to change or update these Terms, or any other of our policies or practices, at any time, and will notify users by posting such changed or updated Terms on this page. Any changes or updates will be effective immediately upon posting to www.being.zone. Your continued use of the Services and Products constitutes your Agreement to abide by the Terms as changed. Under certain circumstances we may also elect to notify you of changes or updates to our Terms by additional means, such as pop-up or push notifications within the Products or email. BEINGZONE DOES NOT OFFER MEDICAL ADVICE. ANY CONTENT, SERVICES OR FEATURES THROUGH BEING.ZONE IS FOR INFORMATIONAL PURPOSES ONLY, AND IS NOT INTENDED TO COVER ALL POSSIBLE USES, DIRECTIONS, PRECAUTIONS, DRUG INTERACTIONS, OR ADVERSE EFFECTS. CONTENT, SERVICES OR FEATURES SHOULD NOT BE USED DURING A MEDICAL EMERGENCY OR FOR THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION. PLEASE CONSULT YOUR DOCTOR OR OTHER QUALIFIED HEALTH CARE PROVIDE BEFORE STARTING ANY RECOMMENDED ACTIVITY, CHANGING YOUR DIET, OR CHANGING ANY COURSE OF TREATMENT OR IF YOU HAVE ANY QUESTIONS ABOUT A MEDICAL CONDITION. NEVER DISREGARD PROFESSIONAL MEDICAL ADVICE OR DELAY SEEKING IT BECAUSE OF ANY CONTENT, SERVICE OR FEATURE ON BEING.ZONE.
                </p>

                 <p className="font-bold">
                  Any Questions, Concerns, and Suggestions?
                </p>
                <p>
                  Please contact us at contact@being.zone to report any violations of these Terms of Service or to pose any questions regarding these Terms of Service, the Service, the Site or Mobile Services.
                </p>

              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
